@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (--viewportLarge) {
    gap: 32px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  li {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    padding: 0;
  }

  @media (--viewportLarge) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    padding: unset;
  }
}

.col1 {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (--viewportLarge) {
    width: 300px;
  }
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 268px);
  }
}

.slogan {
  margin: 0;
  padding: 8px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

}

.icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.emailWrapper {
  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }

  .emailInput {
    display: flex;
    flex-direction: row;
    gap: 3px;

    .submit {
      white-space: nowrap;
      padding: 12px 16px 12px 16px;
      border-radius: 2px;
      background-color: #C5FFC2;
      border: 0;
      cursor: pointer;
      transition: var(--transitionStyleButton);
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: #B1E6AF;
      }
    }
  }
}

.listHeader {
  font-weight: 700 !important;
}

.bottomWrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: 24px 24px 0 24px;
  gap: 12px;

  @media (--viewportLarge) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: unset;
    gap: unset;
  }

  .copyright {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #454546;
    margin: 0;
    padding: 0;
  }

  .links {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;

    a {
      color: #454546;
    }

    @media (--viewportLarge) {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }
}